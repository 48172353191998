<template>
  <div class="my-investments">
    <router-view @dashboard-event="(et) => $emit('dashboardEvent', et)"></router-view>
  </div>
</template>

<script>
export default {
  name: "MyInvestments"
}
</script>
